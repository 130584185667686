:root {
  --primary-color: #434ff1;
  --white: #fff;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  /* background-color: #282c34; */
  /* min-height: 100vh; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
  /* color: white; */

  margin: 1rem 0;
  .title {
    font-size: 1em;
    /* text-transform: uppercase; */
    transform: scale(1.15, 1);
    margin: 1rem 0;
    /* color: var(--primary-color); */
    color: var(--white);
    font-weight: 700;
  }

  .train-img {
    height: 24px;
  }
}

.experiment-title {
  /* color: var(--primary-color); */
  text-align: left;
  font-size: 1.25rem;
  transform: scale(1.1, 1);
  transform-origin: left;
  font-weight: 600;
  /* background: linear-gradient(135deg, #764ba2 0%, #667eea 100%) !important; */
  background: -webkit-linear-gradient(#764ba2, #667eea);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.experiment {
  width: 400px;
  margin: 1rem auto;
  text-align: left;

  .section-title {
    font-size: 0.75rem;
    font-weight: 400;
    margin: 0.5rem 0;
    opacity: 0.5;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.section-title {
  font-size: 0.75rem;
  font-weight: 400;
  margin: 0.5rem 0;
  opacity: 0.5;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.section-content {
  border: 1px solid rgba(5, 5, 5, 0.06);
  border-radius: 12px;
  padding: 0.75rem 0rem;
  .ant-row {
    margin: 0rem 0.75rem;
  }

  .field-label {
    font-size: 0.725rem;
    font-weight: 500;
    opacity: 0.75;
    padding-bottom: 0.25rem;
    /* margin: 0.5rem 0; */
  }
}

.generate-workout {
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  transition: background 0.3s ease;
}
.generate-workout:hover {
  background: linear-gradient(135deg, #764ba2 0%, #667eea 100%) !important;
  transition: background 0.3s ease;
}

.section {
  margin: 1rem 0;
}

.content {
  max-width: 400px;
  margin: 0 auto;
  height: 85vh;
  overflow: auto;
  padding-bottom: 2rem;
}

.workout-details {
  text-align: left;

  .title {
    font-size: 1rem;
    font-weight: 700;
    color: var(--primary-color);
    transform: scale(1.1, 1);
    transform-origin: left;
  }

  .subtitle {
    font-size: 0.75rem;
    font-weight: 500;
    /* margin: 0.5rem 0; */
    opacity: 0.5;
  }
}

.content {
  .ant-card-body {
    min-height: 100%;
    display: flex;
  }
}
.template {
  text-align: left;

  .field-label {
    font-size: 0.75rem;
    color: grey;
  }
  .details-row {
    margin-top: 0.25rem;
  }
}
.exercise {
  font-size: 1rem;
  text-align: start;
  font-weight: 600;
  color: var(--primary-color);
}

html{
  /* height: 100%; */
  background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  /* min-height: 100vh; */
  width: 100%;
  overflow-x: hidden;
}
body {
  font-family:  'Rethink Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: rgb(97,146,246); */
  /* background: linear-gradient(98deg, rgba(97,146,246,0.34919905462184875) 23%, rgba(112,160,251,0.4052214635854342) 45%, rgba(135,155,242,0.45284051120448177) 84%); */
  /* height: 100%; */
  min-height: 100vh;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


